import "@nationwide-bolt/css-core/dist/css/bolt-core.css";
import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Gauge } from "../common/gauge";
import {
  CollapsedPortlet,
  PortletLoading,
  WorkdayPortlets
} from "../common/portlet";
import { PortletInfo } from "../common/portletInfo";
import Spinner from "../common/spinner";
import config from "../config.json";
import "../main.css";

class FOW extends Component {
  state = {
    blurred: "no",
    totalId: 0,
    displayInfo: false
  };

  componentDidMount() {
    this.overall = 0;
  }

  handleBlurChange = () => {
    if (this.state.blurred === "yes") {
      this.setState({ blurred: "no" });
    } else {
      this.setState({ blurred: "yes" });
    }
  };

  handleRefresh = async () => {
    this.props.populateFOWPortal(true);
    // toast.success("Your Time has been refreshed");
  };

  showInformation = () => {
    const { displayInfo } = this.state;
    console.log("fow.displayInfo", displayInfo);
    this.setState({ displayInfo: !displayInfo });
  };

  drawTotal = target => {
    try {
      console.log("fow.drawTotal", "target", target);
      if (target) {
        let iTarget = parseInt(target);
        let fTarget = parseFloat(target);
        if (iTarget > 100) {
          iTarget = 100;
          fTarget = 100;
        }
        if (iTarget > 0) {
          if (fTarget >= 99.5) {
            document.getElementById("displayTotalCompleted").style.width =
              "99.5%";
            document.getElementById(
              "displayTotalCompleted"
            ).style.borderRadius = "12px";
          } else {
            let width = target + "%";
            if (iTarget > 98) {
              width = "98%";
            }

            document.getElementById(
              "displayTotalCompleted"
            ).style.width = width;
            document.getElementById(
              "displayTotalCompleted"
            ).style.borderRadius = "12px 0px 0px 12px";
          }
        } else {
          document.getElementById("displayTotalCompleted").style.width = "20px";
          document.getElementById("displayTotalCompleted").style.borderRadius =
            "10px";
        }
      } else {
        document.getElementById("displayTotalCompleted").style.width = "20px";
        document.getElementById("displayTotalCompleted").style.borderRadius =
          "10px";
      }
    } catch (e) {
      console.log("fow.drawtotal error", e);
    }
  };

  drawLead = target => {
    try {
      console.log("fow.drawLead", "target", target);
      if (target) {
        let fTarget = parseFloat(target);
        if (fTarget > 100) {
          fTarget = 100;
        }
        const deg = (fTarget / 100) * 180;
        document.getElementById("leadGraph").style.transform =
          "rotate(" + deg + "deg)";
        document.getElementById("leadGraph2").style.transform =
          "rotate(" + deg + "deg)";
      }
    } catch (e) {
      console.log("fow.drawLead error", e);
    }
  };

  drawDigital = target => {
    try {
      console.log("fow.drawDigital", "target", target);
      if (target) {
        let fTarget = parseFloat(target);
        if (fTarget > 100) {
          fTarget = 100;
        }
        const deg = (fTarget / 100) * 180;
        document.getElementById("digitalGraph").style.transform =
          "rotate(" + deg + "deg)";
        document.getElementById("digitalGraph2").style.transform =
          "rotate(" + deg + "deg)";
      }
    } catch (e) {
      console.log("fow.drawDigital error ", e);
    }
  };

  returnLeadGauge(fowData, id) {
    if (fowData) {
      const displayPct = fowData.leadCompletion + "";
      return (
        <Gauge
          displayPct={displayPct}
          labelText="Lead / Innovate / Adapt Learning Completion"
          id={id}
        />
      );
    }
    return <div>No data</div>;
  }

  returnDigitalGauge(fowData, id) {
    if (fowData) {
      const displayPct = fowData.digitalCompletion + "";
      return (
        <Gauge
          displayPct={displayPct}
          labelText="Digital Learning Completion"
          id={id}
        />
      );
    }
    return <div>No data</div>;
  }

  returnOverall(fowData) {
    // const today = new Date();
    const fowYear = fowData.fowYear;
    const overallPct = fowData.overall;
    return (
      <React.Fragment>
        <h2 className="fowText">
          {" "}
          {fowYear} Future of Work Certificate Completion
          <span className="fowOverallPct">{overallPct}%</span>
        </h2>
        <div id="totalCompleted" title={overallPct + "%"} className="fowBar">
          <div
            id="displayTotalCompleted"
            className="fowOverall"
            title={overallPct + "%"}
          ></div>
        </div>
      </React.Fragment>
    );
  }

  returnNoFowRequried(fowData, fowDefaultActive, deactivateFow) {
    return (
      <div>
        <h2 className="tile-header">
          <button
            style={
              fowDefaultActive === "N"
                ? { display: "inline-block" }
                : { display: "none" }
            }
            onClick={deactivateFow}
            className="link"
          >
            <img
              src="/chevron_up.png"
              alt="click to collapse future of work"
              width="19px"
              style={{ marginRight: "10px" }}
            />
          </button>
          Future of Work Certificate
        </h2>
        <hr className="tile-line" />
        <div className="fowSubTitle">
          You do not have any required learning required for the current year.
        </div>
        <div className="fowText">
          Each year, associates are expected to complete 8 hours of training to
          receive their Future of Work Certificate. 4 hours of Digital Learning
          AND 4 hours of Lead / Innovate / Adapt Learning. Learn more below.
        </div>

        <div className="tile-subbox" style={{ marginTop: "15px" }}>
          <div className="tile-rectangle fowButtonContainer">
            <img
              className="tile-externallink"
              alt="external-link"
              src="/external-link.svg"
            />
            <a
              className="tile-recttitle "
              href={config.fowLearnPlanUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              My FOW Learning Plan
            </a>
          </div>
        </div>
        <div style={{ paddingTop: "8px" }}></div>
      </div>
    );
  }

  renderButtons() {
    return (
      <div className="tile-subbox " style={{ marginTop: "15px" }}>
        <div className="row">
          <div className="col tileTwocolumn">
            <div className="tile-rectangle fowButtonContainer1">
              <img
                className="tile-externallink"
                alt="external-link"
                src="/external-link.svg"
              />
              <a
                className="tile-recttitle"
                href={config.fowLearnPlanUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                My FOW Learning Plan
              </a>
            </div>
          </div>
          <div className="col tileTwocolumn">
            <div className="tile-rectangle fowButtonContainer2">
              <img
                className="tile-externallink"
                alt="external-link"
                src="/external-link.svg"
              />
              <a
                className="tile-recttitle"
                href={config.wdFowUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                My Workday Dashboard
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  returnContent(fowData, fowDefaultActive, deactivateFow) {
    console.log("FOW.returnContent fowData", fowData);
    const { displayInfo } = this.state;
    // const today = new Date();
    const fowYear = fowData.fowYear;
    let informationMessge = `Associates are expected to complete 8 hours of training in ${fowYear} to
      receive their Future of Work Certificate. 4 hours of Digital
      Learning AND 4 hours of Lead / Innovate / Adapt Learning. Hours may
      be adjusted for new associates.`;

    if (fowData) {
      //Received data
      if (fowData.error) {
        //There is an error.
        console.log("FOW.returnContent -- Error", fowData);
        return (
          <div>
            <h2 className="tile-header">
              <button
                style={
                  fowDefaultActive === "N"
                    ? { display: "inline-block" }
                    : { display: "none" }
                }
                onClick={deactivateFow}
                className="link"
              >
                <img
                  src="/chevron_up.png"
                  alt="Click to collapse future of work"
                  width="19px"
                  style={{ marginRight: "10px" }}
                />
              </button>
              Future of Work Certificate
            </h2>
            <hr className="tile-line" />
            <div className="fowSubTitle">
              My Progress{" "}
              <span className="infoLink" onClick={this.showInformation}>
                {displayInfo ? "X" : "?"}
              </span>
            </div>
            <div className="fowText">
              {" "}
              {fowYear} Future of Work Certificate Completion
            </div>
            <div className="portletError">
              <div className="portletErrorAlert">!</div>
              <div
                style={{
                  fontFamily: "nw-primary",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#4D4F53"
                }}
              >
                Oops, this is unexpected.
              </div>
              <div
                style={{
                  fontFamily: "nw-primary",
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#4D4F53"
                }}
              >
                Error code: 500
                <br />
                An error has occurred and we're working to fix the issue.
                <br />
                If the issue persists please{" "}
                <a
                  style={{ color: "#4D4F53", textDecoration: "underline" }}
                  href="https://nwproduction.service-now.com/csm"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  contact us.
                </a>
              </div>
              <div
                className="tile-apierror-refresh"
                onClick={this.handleRefresh}
                title="Refresh"
              >
                Refresh
              </div>
            </div>
            {this.renderButtons()}

            <PortletInfo
              position="-300px"
              message={informationMessge}
              showing={displayInfo}
              title="Future of Work"
            />
          </div>
        );
      }

      //Valid data received
      const overallPct = fowData.overall;
      const showComplete = parseInt(overallPct) === 100 ? true : false;
      if (fowData.newHire) {
        //This is for a new hire, so set the new hire information message
        informationMessge = `Associates are expected to complete 8 hours of training in ${fowYear} to
      receive their Future of Work Certificate. 4 hours of Digital
      Learning AND 4 hours of Lead / Innovate / Adapt Learning. Hours may
      be adjusted for new associates.`;
      }
      return (
        <div>
          <h2 className="tile-header">
            <button
              style={
                fowDefaultActive === "N"
                  ? { display: "inline-block" }
                  : { display: "none" }
              }
              onClick={deactivateFow}
              className="link"
            >
              <img
                src="/chevron_up.png"
                alt="click to collapse future of work"
                width="19px"
                style={{ marginRight: "10px" }}
              />
            </button>
            Future of Work Certificate
          </h2>
          <hr className="tile-line" />
          <div className="fowSubTitle">
            My Progress{" "}
            <span className="infoLink" onClick={this.showInformation}>
              {displayInfo ? "X" : "?"}
            </span>
          </div>
          {this.returnOverall(fowData)}

          <div
            className="fowText"
            style={
              showComplete
                ? {
                    display: "block"
                  }
                : { display: "none" }
            }
          >
            <span style={{ fontWeight: "bold" }}>Great work!</span> You've
            earned your certificate for this year!{" "}
            <a
              href="https://www.myworkday.com/nationwide/d/task/1422$3757.htmld"
              target="blank"
            >
              View your completion certificate.
            </a>
          </div>
          <div
            className="fowText"
            style={
              !showComplete
                ? {
                    display: "block"
                  }
                : { display: "none" }
            }
          >
            Normally, credits post the next business day. Delayed credits will post by the following Monday afternoon. 
          </div>
          <div
            className="fowMobileSize container-fluid"
            style={{ marginTop: "10px" }}
          >
            <div className="row">
              <div className="col">
                {this.returnDigitalGauge(fowData, "digitalGraph")}
              </div>
            </div>
            <div className="row">
              <div
                className="col"
                style={{
                  borderLeftColor: "gray",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1px"
                }}
              >
                {this.returnLeadGauge(fowData, "leadGraph")}
              </div>
            </div>
          </div>
          <div
            className="fowBrowserSize container-fluid"
            style={{ marginTop: "10px" }}
          >
            <div className="row">
              <div className="col">
                {this.returnDigitalGauge(fowData, "digitalGraph2")}
              </div>
              <div
                className="col"
                style={{
                  borderLeftColor: "gray",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1px"
                }}
              >
                {this.returnLeadGauge(fowData, "leadGraph2")}
              </div>
            </div>
          </div>
          {this.renderButtons()}

          <div style={{ paddingTop: "8px" }}></div>
          <PortletInfo
            position="-300px"
            message={informationMessge}
            showing={displayInfo}
            title="Future of Work"
          />
        </div>
      );
    } else {
      //No data received
      return <Spinner />;
    }
  }

  render() {
    const {
      fowData,
      activePortal,
      fetchFowData,
      fowDefaultActive,
      deactivateFow
    } = this.props;

    this.overall = 0;
    if (activePortal === "Y") {
      console.log("FOW.render ...  fowData", fowData);
      if (fowData) {
        //Active portal with data
        const noFowRequired = fowData.noFowRequired;
        if (noFowRequired) {
          //Associate is not required to complete FOW i.e. hireDate >= notNeededHireDate
          const content = this.returnNoFowRequried(
            fowData,
            fowDefaultActive,
            deactivateFow
          );
          return (
            <WorkdayPortlets content={content} />
          );
        }

        const target = parseFloat(fowData.overall);
        const leadTarget = parseFloat(fowData.leadCompletion);
        const digitalTarget = parseFloat(fowData.digitalCompletion);
        const t = setTimeout(this.drawTotal, 300, target);
        const t1 = setTimeout(this.drawLead, 300, leadTarget);
        const t2 = setTimeout(this.drawDigital, 300, digitalTarget);
        console.log("fow.render setTimeouts", t, t1, t2);
        const content = this.returnContent(
          fowData,
          fowDefaultActive,
          deactivateFow
        );
        return (
          <WorkdayPortlets content={content} />
        );
      } else {
        //Active portal but No FOW data
        console.log("FOW .render... call populateFOWPortal");
        this.props.populateFOWPortal();
        return <PortletLoading title="Future of Work" />;
      }
    } else {
      //Portal is not active
      return (
        <CollapsedPortlet
          // activateAwsPortal={this.handleExpand}
          dataFetch={fetchFowData}
          title="Future of Work"
        ></CollapsedPortlet>
      );
      // return null;
    }
  }
}

export default FOW;
