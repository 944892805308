import config from "../config.json";
export function getCallbackURI() {
  const LOCAL = config.LOCAL;
  const DEV = config.DEV;
  const EXTDEV = config.EXTDEV;
  const EXTDEV2 = config.EXTDEV2;
  const TEST = config.TEST;
  const PT = config.PT;
  const TESTPROXY = config.TESTPROXY;

  const loc = window.location.hostname;
  console.log("EnvironmentVariables.getCallbackURI loc", loc);
  // console.log(
  //   "EnvironmentVariables.getCallbackURI windows.location.hostname",
  //   "|" + loc + "|"
  // );
  // console.log("EnvironmentVariables.getCallbackURI LOCAL", "|" + LOCAL + "|");
  // console.log("EnvironmentVariables.getCallbackURI DEV", "|" + DEV + "|");
  // console.log("EnvironmentVariables.getCallbackURI EXTDEV", "|" + EXTDEV + "|");
  // console.log(
  //   "EnvironmentVariables.getCallbackURI EXTDEV2",
  //   "|" + EXTDEV2  + "|"
  // );
  // console.log("EnvironmentVariables.getCallbackURI TEST", "|" + TEST + "|");
  // console.log("EnvironmentVariables.getCallbackURI PT", "|" + PT + "|");
  switch (loc) {
    case LOCAL:
      console.log("EnvironmentVariables.getCallbackURI you are in LOCAL");
      return config.local_redirect_uri;

    case DEV:
      console.log("EnvironmentVariables.getCallbackURI you are in DEV");
      return config.dev_redirect_uri;

    case EXTDEV:
      console.log("EnvironmentVariables.getCallbackURI you are in EXTDEV");
      return config.extdev_redirect_uri;

    case EXTDEV2:
      console.log("EnvironmentVariables.getCallbackURI you are in EXTDEV2");
      return config.extdev2_redirect_uri;

    case TEST:
      console.log("EnvironmentVariables.getCallbackURI you are in TEST");
      return config.test_redirect_uri;

    case PT:
      console.log("EnvironmentVariables.getCallbackURI you are in PT");
      return config.pt_redirect_uri;

    case TESTPROXY:
      console.log("EnvironmentVariables.getCallbackURI you are in Test Proxy");
      return config.testproxy_redirect_uri;
    default:
      console.log("EnvironmentVariables.getCallbackURI you are in Prod");
      return config.redirect_uri;
  }
}
export function getEnvironment() {
  const LOCAL = config.LOCAL;
  const DEV = config.DEV;
  const TEST = config.TEST;
  const PT = config.PT;
  const EXTDEV = config.EXTDEV;
  const EXTDEV2 = config.EXTDEV2;
  const loc = window.location.hostname;
  console.log("EnvironmentVariables.getEnvironment loc", loc);
  switch (loc) {
    case LOCAL:
      return "LOCAL";
    case DEV:
      return "DEFAULT";
    case EXTDEV:
      return "DEFAULT";
    case EXTDEV2:
      return "DEFAULT";
    case TEST:
      return "DEFAULT";
    case PT:
      console.log("switch is in PT");
      return "DEFAULT";
    default:
      console.log("switch is in Prod");
      return "DEFAULT";
  }
}

