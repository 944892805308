import config from "../config.json";
import { getRandomString } from "./AuthService";
import { getEnvironment } from "./EnvironmentVariable";
import { isMobile } from "react-device-detect";

export const getBenefits = async () => {
  const environ = getEnvironment();
  let benefitsAPI = config.benefitsAPI;
  let portalKey = config.benefitsKey;
  if (environ !== "DEFAULT") {
    console.log("jobService.getJobs environ", environ);

    if (isMobile) {
      benefitsAPI = config.Mobile_test_benefitsAPI;
    } else {
      benefitsAPI = config.test_benefitsAPI;
      portalKey = config.test_benefitsKey;
    }
  }

  // const user = JSON.parse(sessionStorage.getItem("user"));
  const userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
  const message = getRandomString(25);
  const token = sessionStorage.getItem("access_token");

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-NW-Message-ID", message);
  headers.append("Authorization", "Bearer " + token);
  headers.set("client_id", portalKey);
  const shortName = userInfo.shortName
    ? userInfo.shortName.toUpperCase()
    : userInfo.username.toUpperCase();
  const body = {
    shortName: shortName
  };

  const response = await fetch(benefitsAPI, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body)
  });

  if (response.ok) {
    try {
      const jsondata = await response.json();
      return jsondata;
    } catch (e) {
      console.log("getBenefits error", e);
      return null;
    }
  }
  return null;
};
